@use 'mixins' as m;
@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';

:root {
  .transition-effect {
    left: 0px !important;
  }

  ion-content::part(transitionEffect) {
    left: 0px !important;
    opacity: 0px;
  }

  /** Global custom loading spinner **/
  ion-loading.custom-loading-spinner {
    --backdrop-opacity: 0px;
    -ms-transition: background-color 0.3 linear;
    -webkit-transition: background-color 0.3 linear;
    background-color: var(--ion-color-shade-0);
    transition: background-color 0.3 linear;

    .loading-wrapper {
      background: var(--ion-color-transparent) !important;
      box-shadow: none !important;
    }

    ion-spinner {
      color: var(--ion-color-primary-500);
      height: 40px;
      width: 40px;
    }
  }

  .footer-global {
    --background: var(--ion-color-shade-0);

    ion-toolbar {
      --background: var(--ion-color-shade-0);
    }
  }

  .header-color {
    --background: var(--ion-color-shade-0);
  }

  .header-custom,
  .custom-menu-header {
    &::after {
      background-image: none;
    }
  }

  ion-item.input-item.item-has-focus,
  ion-item.input-item.item-has-value {
    show-hide-password {
      .eye-icon {
        color: var(--ion-color-neutral-600) !important;
      }
    }
  }

  ion-toggle {
    --background-checked: var(--ion-color-primary-500);
    --background: var(--ion-color-neutral-200);
    --handle-background-checked: var(--ion-color-shade-0);
    --handle-background: var(--ion-color-shade-0);
    --handle-box-shadow: none;
    --handle-height: 20px;
    --handle-width: 20px;
    height: 24px;
    width: 42px;

    &.small {
      --handle-height: 12px;
      --handle-width: 12px;
      height: 16px;
      width: 28px;
    }
  }

  ion-item.item-has-focus,
  ion-item.item-has-value {
    ion-label.input-title.label-floating {
      color: var(--ion-color-neutral-600) !important;
      transform: translateY(15%) scale(0.75) !important;
    }
  }

  // Autofill Inputs
  ion-item.item-has-focus {
    ion-input {
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0px 1000px var(--ion-color-secondary-100) inset !important;
        -webkit-text-fill-color: var(--ion-color-neutral-700) !important;
        box-shadow: 0 0 0 30px var(--ion-color-secondary-100) inset !important;
        text-fill-color: var(--ion-color-neutral-700) !important;
      }
    }
  }

  // Autofill Inputs
  ion-input {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0px 1000px var(--ion-color-neutral-100) inset !important;
      -webkit-text-fill-color: var(--ion-color-neutral-700) !important;
      box-shadow: 0 0 0 30px var(--ion-color-neutral-100) inset !important;
      text-fill-color: var(--ion-color-neutral-700) !important;
    }

    input[type='file'] {
      cursor: pointer;

      input[type='button'] {
        cursor: pointer;
      }
    }
  }

  ion-input.field-label-text-disabled {
    color: var(--ion-color-neutral-400);

    .native-input[disabled].sc-ion-input-ios {
      color: var(--ion-color-shade-100);
    }
  }

  // Reset Password Class
  .code-class {
    margin-top: 62px;

    @include m.on-view-min(lg) {
      margin-top: 32px;
    }
  }

  a.cancel-button {
    color: var(--ion-color-dark-shade);
    font-size: var(--ion-font-size-small);
    text-decoration: none;
  }

  ion-button {
    // Reusable Primary Button
    // From
    &.small {
      height: var(--ion-height-small) !important;
      font-size: var(--ion-font-size-small) !important;
    }

    &.x-small {
      height: var(--ion-height-x-small) !important;
      font-size: var(--ion-font-size-x-small) !important;
    }

    &.xx-small {
      height: var(--ion-height-xx-small) !important;
      font-size: var(--ion-font-size-x-small) !important;
    }

    &.rounded {
      height: 40px !important;
      width: 40px !important;
    }

    &.primary-button {
      --background-active: var(--ion-color-primary-700);
      --background-hover: var(--ion-color-primary-700);
      --background: var(--ion-color-primary-500);
      --border-radius: var(--ion-border-radius-common);
      --border: 1px solid var(--ion-color-primary-500);
      --box-shadow: none;
      --inner-padding-end: 0px;
      --inner-padding-start: 0px;
      --padding-end: 0px;
      --padding-start: 0px;
      font-size: var(--ion-font-size-mid-medium);
      font-weight: var(--ion-font-weight-semi-bold);
      height: var(--ion-height-medium);
      line-height: var(--ion-line-height-medium);
      opacity: 1;
      margin: 0px !important;

      @include m.on-view-min(lg) {
        height: var(--ion-height-small);
      }

      &.button-disabled {
        --background: var(--ion-color-primary-200);
      }

      &.publication-proof.button-disabled,
      &.submission.button-disabled {
        --background-active: var(--ion-color-neutral-100);
        --background-hover: var(--ion-color-neutral-100);
        --background: var(--ion-color-neutral-100);
        --color: var(--ion-color-neutral-400);
      }
    }

    &.success-button {
      --background-active: var(--ion-color-success-700) !important;
      --background-hover: var(--ion-color-success-700) !important;
      --background: var(--ion-color-success-500) !important;
      --border-radius: var(--ion-border-radius-common) !important;
      --border: 1px solid var(--ion-color-success-500) !important;
      --box-shadow: none;
      --inner-padding-end: 0px;
      --inner-padding-start: 0px;
      --padding-end: 0px;
      --padding-start: 0px;
      font-size: var(--ion-font-size-mid-medium);
      font-weight: var(--ion-font-weight-semi-bold);
      height: var(--ion-height-medium);
      line-height: var(--ion-line-height-medium);
      opacity: 1;
      margin: 0px !important;

      @include m.on-view-min(lg) {
        height: var(--ion-height-small);
      }

      &.button-disabled {
        --background: var(--ion-color-success-200);
      }
    }

    // Reusable Gray Button
    // From
    &.gray-button {
      --background-active: var(--ion-color-neutral-500);
      --background-hover: var(--ion-color-neutral-500);
      --background: var(--ion-color-neutral-700);
      --border-radius: var(--ion-border-radius-common);
      --border: 1px solid var(--ion-color-neutral-700);
      --box-shadow: none;
      --inner-padding-end: 0px;
      --inner-padding-start: 0px;
      --padding-end: 0px;
      --padding-start: 0px;
      font-size: var(--ion-font-size-small);
      font-weight: var(--ion-font-weight-semi-bold);
      height: var(--ion-height-small);
      line-height: var(--ion-line-height-medium);
      opacity: 1;
      margin: 0px !important;

      &[disabled] {
        --background: var(--ion-color-neutral-100);
        color: var(--ion-color-neutral-400);
      }
    }

    &.cancel-button {
      --border-radius: var(--ion-border-radius-common);
      --box-shadow: none;
      --inner-padding-end: 0px;
      --inner-padding-start: 0px;
      --padding-end: 0px;
      --padding-start: 0px;
      --background-active: var(--ion-color-neutral-100);
      --background-hover: var(--ion-color-neutral-100);
      --background: var(--ion-color-neutral-100);
      --border-radius: var(--ion-border-radius-common);
      color: var(--ion-color-neutral-500);
      font-size: var(--ion-font-size-small);
      font-weight: var(--ion-font-weight-semi-bold);
      line-height: var(--ion-line-height-medium);
      height: var(--ion-height-small);
      text-transform: capitalize;
      text-decoration: none;
      opacity: 1;
      margin: 0px !important;
    }

    &.danger-button {
      --border-radius: var(--ion-border-radius-common);
      --box-shadow: none;
      --inner-padding-end: 0px;
      --inner-padding-start: 0px;
      --padding-end: 0px;
      --padding-start: 0px;
      --background-active: var(--ion-color-error-600);
      --background-hover: var(--ion-color-error-600);
      --background-activated: var(--ion-color-error-600);
      --background-focused: var(--ion-color-error-600);
      --background: var(--ion-color-error-600);
      color: var(--ion-color-shade-0);
      font-size: var(--ion-font-size-small);
      font-weight: var(--ion-font-weight-semi-bold);
      line-height: var(--ion-line-height-medium);
      height: var(--ion-height-small);
      text-transform: capitalize;
      text-decoration: none;
      opacity: 1;
      margin: 0px !important;

      &.button-disabled {
        --background: var(--ion-color-error-200);
      }
    }

    &.transparent-button {
      --box-shadow: none;
      --inner-padding-end: 0px;
      --inner-padding-start: 0px;
      --padding-end: 0px;
      --padding-start: 0px;
      --background-active: transparent;
      --background-hover: transparent;
      --background-activated: transparent;
      --background-focused: transparent;
      --background: transparent;
      color: var(--ion-color-neutral-500);
      font-size: var(--ion-font-size-small);
      font-weight: var(--ion-font-weight-regular);
      line-height: var(--ion-line-height-medium);
      height: var(--ion-height-small);
      text-transform: capitalize;
      letter-spacing: 0em;

      &.button-disabled {
        opacity: 0.25;
      }
    }

    &.warning-button {
      --background-active: var(--ion-color-warning-700);
      --background-hover: var(--ion-color-warning-700);
      --background: var(--ion-color-warning-500);
      --border-radius: var(--ion-border-radius-common);
      --border: 1px solid var(--ion-color-warning-500);
      --box-shadow: none;
      --inner-padding-end: 0px;
      --inner-padding-start: 0px;
      --padding-end: 0px;
      --padding-start: 0px;
      font-size: var(--ion-font-size-mid-medium);
      font-weight: var(--ion-font-weight-semi-bold);
      height: var(--ion-height-large);
      line-height: var(--ion-line-height-large);
      opacity: 1;
      text-transform: capitalize;

      &.content-approbation {
        &.button-disabled {
          --background-active: var(--ion-color-neutral-100);
          --background-hover: var(--ion-color-neutral-100);
          --background: var(--ion-color-neutral-100);
          color: var(--ion-color-neutral-400);
          opacity: 1;
        }
      }

      &.button-disabled {
        opacity: 0.25;
      }

      &.small {
        font-size: var(--ion-font-size-small) !important;
      }
    }

    &.stretch-button {
      height: var(--ion-height-small);
    }

    &.small-button {
      height: var(--ion-height-xx-small);
    }
  }

  ion-toast.alert-toast-class {
    i-feather {
      height: 30px;
      width: 30px;
    }

    .toast-button-cancel {
      color: var(--ion-color-neutral-900) !important;
    }
  }

  ion-toast.alert-toast-class::part(button) {
    --button-color: var(--ion-color-secondary-500) !important;
    --color: var(--ion-color-secondary-500) !important;
  }

  .custom-toast-alert {
    position: absolute;
    transition: transform 0.5s ease-in-out;
    width: 100%;
    z-index: 100;

    &.top {
      top: 0px;
      transform: translateY(-200px);
    }

    &.bottom {
      bottom: 0px;
      transform: translateY(200px);
    }

    &.show {
      transition: transform 0.5s ease-in-out;
      transform: translateY(0);
    }
  }

  // Reusable input item
  // From
  ion-item.input-item {
    --inner-padding-end: 0px;
    --inner-padding-start: 0px;
    --padding-end: 0px;
    --padding-start: 0px;
  }

  ion-label.input-title {
    color: var(--ion-color-neutral-600);
    font-size: var(--ion-font-size-small);
    font-weight: var(--ion-font-weight-regular);
    margin-top: 4px;
    transform: translateY(20px);

    @include m.on-view-min(lg) {
      transform: translateY(15%) scale(0.75) !important;
    }
  }

  .item-label-floating {
    --min-height: 56px;
    --padding-bottom: var(--ion-padding-xx-small) !important;
    height: 56px;

    @include m.on-view-min(lg) {
      height: 70px;
    }

    &.item-has-value,
    &.item-has-focus {
      height: 85px;

      @include m.on-view-min(lg) {
        height: 70px;
      }
    }

    .input-field {
      pointer-events: none;
    }
  }

  .input-field {
    --background: var(--ion-color-neutral-100);
    --border-color: var(--ion-color-neutral-100);
    --color: var(--ion-color-neutral-700);
    --padding-bottom: var(--ion-padding-xx-small);
    --padding-top: var(--ion-padding-xx-small);
    --placeholder-color: var(--ion-color-neutral-400);
    --placeholder-opacity: 1;
    border-radius: var(--ion-border-radius-small);
    border: 1px solid var(--ion-color-neutral-100);
    font-size: var(--ion-font-size-mid-medium);
    font-weight: var(--ion-font-weight-regular);
    height: var(--ion-height-large);
    min-height: var(--ion-height-large);
    opacity: 1 !important;
    position: absolute;
    width: 100%;

    @include m.on-view-min(lg) {
      position: inherit;
      height: var(--ion-height-x-small);
      min-height: var(--ion-height-x-small);
    }

    &.password {
      padding-right: 44px !important;

      @include m.on-view-min(lg) {
        height: var(--ion-height-x-small);
        min-height: var(--ion-height-x-small);
      }
    }

    &.has-value {
      position: inherit;
    }

    &.has-focus {
      --background-activated-opacity: 1;
      --background-activated: var(--ion-color-secondary-100);
      --background-focused-opacity: 1;
      --background-focused: var(--ion-color-secondary-100);
      --background: var(--ion-color-secondary-100);
      --border-color: var(--ion-color-secondary-400);
      --color: var(--ion-color-neutral-700);
      --inner-padding-end: 0px;
      --inner-padding-start: 0px;
      --padding-bottom: var(--ion-padding-xx-small);
      --padding-top: var(--ion-padding-xx-small);
      background: var(--ion-color-secondary-100);
      border-color: var(--ion-color-secondary-400);
      position: inherit;
    }

    &.invalid {
      --background: var(--ion-color-neutral-100);
      --color: var(--ion-color-neutral-700);
      --inner-padding-end: 0px;
      --inner-padding-start: 0px;
      --padding-bottom: var(--ion-padding-xx-small);
      --padding-top: var(--ion-padding-xx-small);
      background: var(--ion-color-neutral-100);
      border-radius: var(--ion-border-radius-small);
      border: 1px solid var(--ion-color-error-500);
      width: 100%;
    }
  }

  .input-error {
    color: var(--ion-color-error-500);
    font-size: var(--ion-font-size-x-small);
  }

  // To here

  .strength-popover {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0);
    --box-shadow: 0px;
    --max-height: auto;
    --width: auto;
    font-size: var(--ion-font-size-x-small);
    font-weight: var(--ion-font-weight-regular);
    line-height: var(--ion-line-height-small);
    margin-left: 3px;
    margin-right: 3px;

    @include m.on-view-min(lg) {
      margin-left: 50%;
      margin-right: 25%;
    }

    &::part(content) {
      left: 0px !important;
      right: 0px !important;
    }

    &::part(arrow) {
      display: flex !important;
      height: 16px;
      width: 16px;
      left: 20px !important;
      transform: rotate(315deg);
      background: var(--ion-color-neutral-700);
      margin-top: 19px;
      z-index: 11;

      &::after {
        background: var(--ion-color-neutral-700);
        border-radius: 2px;
        transform: rotate(1deg);
        height: 100%;
        width: 100%;
      }
    }

    .content-sizing {
      --background: var(--ion-color-neutral-700);
      --color: var(--ion-color-neutral-50);
      --padding-bottom: 16px;
      --padding-end: 16px;
      --padding-start: 16px;
      --padding-top: 16px;
      border-radius: 8px;
      width: auto;

      &::part(scroll) {
        overflow-y: hidden;
      }
    }

    .strength-text-bold {
      font-weight: var(--ion-font-weight-semi-bold);
    }
  }

  // Scrollable Header
  .header-scrollable {
    top: 0px;
    transition: all 0.3s ease;
    width: 100%;
    z-index: 100;

    &.hidden {
      top: -200px;
    }

    &::after {
      background-image: none;
    }
  }

  // Scrollable Content
  .common-content {
    background: var(--ion-color-neutral-800);

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
      /* make scrollbar transparent */
    }

    .wrapper {
      background: var(--ion-color-neutral-800);
      height: 100%;
      overflow-y: scroll;
      width: 100%;

      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
        /* make scrollbar transparent */
      }

      @include m.on-view-min(lg) {
        background: var(--ion-color-shade-0);
      }
    }

    @include m.on-view-min(lg) {
      background: var(--ion-color-shade-0);
    }
  }

  .container {
    background: var(--ion-color-shade-0);
    border-radius: 24px 24px 0px 0px;
    margin-top: 64px; //Header Height
    width: 100%;

    @include m.on-view-min(lg) {
      border-radius: 0px;
      margin-top: 0px;
    }
  }

  .container-no-mt {
    background: var(--ion-color-shade-0);
    border-radius: 24px 24px 0px 0px;
    width: 100%;

    @include m.on-view-min(lg) {
      border-radius: 0px;
      margin-top: 0px;
    }
  }

  // Custom Menu Static
  .custom-menu-static {
    width: 200px;

    @include m.on-view-min(lg) {
      width: 240px;
    }
  }

  .accordion-tab-brand-tasks {
    .tab ion-icon {
      display: none;
    }
  }

  ion-popover.datepicker-desktop {
    --width: auto !important;
  }

  ion-popover.comment-desktop {
    --width: 400px !important;
  }
}

// Not scrollable content
.common-content-not-scrollable {
  background: var(--ion-color-neutral-800);

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    /* make scrollbar transparent */
  }

  .wrapper-not-scrollable {
    background: var(--ion-color-neutral-800);
    height: 100%;
    width: 100%;

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
      /* make scrollbar transparent */
    }

    @include m.on-view-min(lg) {
      background: var(--ion-color-shade-0);
    }

    @include m.on-view-min(lg) {
      background: var(--ion-color-shade-0);
    }

    .container-not-scrollable {
      background: var(--ion-color-shade-0);
      border-radius: 24px 24px 0px 0px;
      width: 100%;

      @include m.on-view-min(lg) {
        border-radius: 0px;
        margin-top: 0px;
      }
    }
  }

  // Footer menu
  .common-menu-tab {
    height: 72px;
  }

  // Custom Menu Static
  .custom-menu-static {
    width: 240px;
  }

  ion-input.edit-icon-container {
    .native-input {
      opacity: 0px;
      padding: 15px;
    }
  }

  // Skeleton
  .skeleton-container {
    --inner-border-width: 0px;
  }

  .ion-page {
    justify-content: flex-start;

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
      /* make scrollbar transparent */
    }
  }

  ion-modal:not(.no-radius) {
    --border-radius: 24px;
  }

  kickflo-frontend-stack-signup {
    validation-code .input-error {
      margin-left: 24px;
    }
  }

  & ion-textarea {
    --background: var(--ion-color-neutral-100);
    --color: var(--ion-color-neutral-900);
    --padding-end: 16px;
    --padding-start: 16px;
    --padding-top: 16px;
    --padding-bottom: 16px;
    --placeholder-color: var(--ion-color-neutral-400);
    --placeholder-font-weight: var(--ion-font-weight-regular);
    font-size: var(--ion-font-size-small);
    font-weight: var(--ion-font-weight-regular);
    line-height: var(--ion-line-height-medium);
    letter-spacing: 0em;
    text-align: left;
    border-radius: 8px;

    &.edit-comment {
      & .native-textarea {
        color: var(--ion-color-neutral-900) !important;
        min-height: 30px;
      }
    }

    & .native-textarea {
      --border-radius: 8px;
      color: var(--ion-color-neutral-700);
    }

    & textarea.native-textarea.sc-ion-textarea-ios::placeholder {
      font-size: var(--ion-font-size-small);
      font-weight: var(--ion-font-weight-regular);
      line-height: var(--ion-line-height-medium);
      letter-spacing: 0em;
      text-align: left;
    }
  }

  ion-datetime.negotiation-publication-date {
    left: 0px;
    min-width: 100%;
    position: fixed;
  }

  ion-popover.collaboration-agreement-popover {
    &::part(content) {
      border-radius: 5px;
      border: 0.5px solid var(--ion-color-neutral-200);
      box-shadow: 0px 10px 15px -3px rgb(0 0 0 / 5%);
      left: 0px !important;
      margin: auto 40px !important;
      min-width: -webkit-fill-available !important;
    }
  }
}

.segment {
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  visibility: hidden;

  &.agreement,
  &.brief {
    max-height: 0px;
    margin: 0px;
    padding: 0px;

    .title {
      max-height: 0px;
    }

    .shown {
      max-height: auto;
    }
  }
}

.shown {
  max-height: auto;
  opacity: 1;
  visibility: visible;
}

// #region Show/hide password workaround hack
.password-eye {
  position: absolute;
  right: 17px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  @include m.on-view-min(lg) {
    top: 38px;
  }

  .eye-icon {
    color: var(--ion-color-neutral-400);
    width: 24px;

    @include m.on-view-min(lg) {
      width: 16px;
    }
  }
}

.password-eye-clickable {
  position: absolute;
  right: 17px;
  top: 18px;
  z-index: 3;
  background-color: transparent;
  width: 24px;
  height: 48px;

  @include m.on-view-min(lg) {
    top: 40px;
    width: 16px;
    height: 16px;
  }
}
// #endregion

.pink-bg {
  background: var(--ion-color-primary-500);
}

.status-rounded {
  border-radius: 100%;
  height: 8px;
  width: 8px;

  &.inactive {
    opacity: 0.3;
  }
}

.clickable {
  cursor: pointer !important;
}

// #region Modals
.todo-modal {
  --height: 592px;
  --width: 440px;
}

.todo-modal-files-only {
  --height: 553px;
  --width: 440px;
}

.todo-modal,
.todo-modal-files-only {
  .backgrounded {
    opacity: 0.5;
    background-color: var(--ion-color-neutral-300);
  }

  ion-button {
    --background: var(--ion-color-primary-500);
    --background-hover: var(--ion-color-primary-500);
    --background-focused: var(--ion-color-primary-500);
    --background-activated: var(--ion-color-primary-500);
    --padding-bottom: 8px;
    --padding-top: 8px;
    --border-radius: var(--ion-border-radius-medium);
    --color: var(--ion-color-shade-0);
    font-size: var(--ion-font-size-x-small);
    font-weight: var(--ion-font-weight-semi-bold);
    line-height: var(--ion-line-height-small);

    &.btn-warning {
      --background: var(--ion-color-warning-500);
      --background-hover: var(--ion-color-warning-500);
      --background-focused: var(--ion-color-warning-500);
      --background-activated: var(--ion-color-warning-500);
    }

    &.btn-success {
      --background: var(--ion-color-success-500);
      --background-hover: var(--ion-color-success-500);
      --background-focused: var(--ion-color-success-500);
      --background-activated: var(--ion-color-success-500);
    }

    &.btn-neutral {
      --background: var(--ion-color-neutral-700);
      --background-hover: var(--ion-color-neutral-700);
      --background-focuses: var(--ion-color-neutral-700);
      --background-activated: var(--ion-color-neutral-700);
    }

    &.btn-submit {
      --border-radius: 24px;
      height: 48px;
      font-size: var(--ion-font-size-small);
      line-height: var(--ion-line-height-medium);
    }

    i-feather {
      width: 16px;
      height: 16px;
      color: var(--ion-color-success-500);
    }
  }

  // Content
  .content-container {
    .files-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .section-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: var(--ion-color-neutral-700);

      i-feather,
      img {
        width: 12px;
        height: 12px;
        color: var(--ion-color-shade-0);
      }
    }

    .section-title {
      font-size: var(--ion-font-size-x-small);
      font-weight: var(--ion-font-weight-semi-bold);
      line-height: var(--ion-line-height-small);
      color: var(--ion-color-neutral-900);
    }

    .right-icon {
      width: 16px;
      height: 16px;
      color: var(--ion-color-secondary-500);
    }

    .right-text {
      font-size: var(--ion-font-size-x-small);
      font-weight: var(--ion-font-weight-semi-bold);
      line-height: var(--ion-line-height-small);
      color: var(--ion-color-neutral-700);
    }

    .file-container {
      height: 255px;
      border-radius: var(--ion-border-radius-small);
    }

    .file-preview-container {
      background-position: center;
      background-size: cover !important;
      border-radius: var(--ion-border-radius-small);

      &-requested {
        border: 2px solid var(--ion-color-warning-500);
      }

      &-approved {
        border: 2px solid var(--ion-color-success-500);
      }

      .video-play-icon {
        height: 36px;
        width: 28px;
      }

      .top-right-overlay {
        background: var(--ion-color-neutral-100);
        border-radius: 50px;
        color: var(--ion-color-neutral-900);
        font-size: var(--ion-font-size-xx-small);
        font-weight: var(--ion-font-weight-regular);
        line-height: var(--ion-line-height-small);
        height: 20px;
        padding: 2px 8px;
        right: 12px;
        top: 12px;
      }

      .trash-icon {
        left: 12px;
        bottom: 12px;
        width: 24px;
        height: 24px;
        color: var(--ion-color-shade-0);
      }

      .btn-overlay {
        bottom: 6px;

        &-reshoot {
          width: 123px;
          right: 140px;
          bottom: 6px;
        }

        &-approve {
          width: 123px;
          right: 12px;
          bottom: 6px;
        }
      }
    }

    .section-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: var(--ion-color-neutral-100);
      border-radius: var(--ion-border-radius-small);
      font-size: var(--ion-font-size-small);
      font-weight: var(--ion-font-weight-regular);
      line-height: var(--ion-line-height-medium);
      color: var(--ion-color-neutral-700);
      height: 56px;
    }

    .section-content-text {
      font-size: var(--ion-font-size-small);
      font-weight: var(--ion-font-weight-regular);
      line-height: var(--ion-line-height-medium);
      color: var(--ion-color-neutral-700);
    }
  }

  // Footer
  ion-footer {
    border-top: 1px solid var(--ion-color-neutral-100);

    &.backgrounded {
      border-top: 1px solid var(--ion-color-neutral-200);
    }
  }
}

.todo-modal-small {
  --height: 352px;
  --width: 440px;
}

.modal-fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0px;
}

.reply-offer-modal {
  --height: 436px;
  --width: 440px;
  --border-radius: 25px !important;
}

.done-deal-modal {
  --height: 592px;
  --width: 480px;
}

.decline-deal-modal {
  --width: 440px;
  // no height passed here because the modal use `auto-height` class
}

.send-counter-modal {
  --width: 440px;
  --height: 520px;
}

.offer-launched-modal {
  --width: 440px;
  --height: 520px;
}

// modal auto heigh
// https://github.com/ionic-team/ionic-framework/issues/16852#issuecomment-449433452
ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }
  --height: auto;
  .ion-page {
    position: relative;
    display: block;
    contain: content;
  }
}

.not-clickable {
  pointer-events: none;
}
